import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './App.css';
import './bootstrap.min.css';
import App from './App';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Header from "./Header";
import Footer from "./Footer";

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <React.StrictMode>
        <BrowserRouter>
            <div className="App wrapper">
                <div className="flex-header shadow-sm z-index-2">
                    <Header />
                </div>
                <div className="flex-content">
                    <Routes>
                        <Route path="/" element={<App />} />
                    </Routes>
                </div>
                <div className="flex-footer">
                    <Footer />
                </div>
            </div>
        </BrowserRouter>
  </React.StrictMode>
);
