import React from 'react';
import { Link } from 'react-router-dom';
import logo from './PSI_Logo.svg'
import icon from './PSI_Logo_Icon.svg'

const Header = () => {

  return (
    <>
        <div className="container-fluid py-2 bg-white">
          <div className="row">
            <div className="col-auto py-2">
              <Link to="/">
                <img src={logo} className="d-none d-sm-block" style={{height:'40px'}} alt="PSI Testing Excellence" />
                <img src={icon} className="d-sm-none" style={{height:'40px'}} alt="PSI Testing Excellence" />
              </Link>
            </div>
            <div className="col-auto py-2 site-title">
                <p>&nbsp;<br/>Pay Your Invoice<br/>&nbsp;</p>
            </div>
          </div>
        </div>
    </>
  );
}

export default Header;