import React, { useState, useEffect } from 'react';
import { useSearchParams } from "react-router-dom";

import braintree from 'braintree-web';

export default function App() {
    const [hostedFieldsInstance, setHostedFieldsInstance] = useState(null);
    const [emailAddress, setEmailAddress] = useState('');
    const [invoiceNumber, setInvoiceNumber] = useState('');
    const [paymentAmount, setPaymentAmount] = useState('');
    const [paymentResult, setPaymentResult] = useState(null);
    const [searchParams, setSearchParams] = useSearchParams({});
    const [clientToken, setClientToken] = useState(null);

    let settingsClientToken = window.__APP_CONFIG__.clientToken;
    //console.log('new client token: ' + newClientToken);

    useEffect(() => {

        if (!!clientToken) {
            setupBraintree(clientToken);
        } else {
            setupBraintree(settingsClientToken);
        }
    }, []);

    function setupBraintree(token) {
        if (!clientToken) {
            setClientToken(token);
        }

        //console.log('instantiating braintree client w/ token: ' + data);

        const queryStringInvoiceNumber = searchParams.get('invoiceNumber');
        const queryStringPaymentAmount = searchParams.get('paymentAmount');
        const queryStringEmailAddress = searchParams.get('emailAddress');

        if (!!queryStringInvoiceNumber) {
            setInvoiceNumber(queryStringInvoiceNumber);
        }

        if (!!queryStringPaymentAmount) {
            const floatValue = parseFloat(queryStringPaymentAmount).toFixed(2);
            setPaymentAmount(floatValue.toString());
        }

        if (!!queryStringEmailAddress) {
            setEmailAddress(queryStringEmailAddress);
        }

        braintree.client.create({
            authorization: token
        }, function (clientErr, clientInstance) {
            if (clientErr) {
                console.error(clientErr);
                return;
            }

            braintree.hostedFields.create({
                client: clientInstance,
                styles: {
                    input: {
                        'font-size': '13px',
                        'font-family': 'Helvetica Neue, Helvetica, sans- serif',
                        color: '#3a3a3a'
                    },
                    ':focus': {
                        color: 'black'
                    }
                },
                fields:
                {
                    number:
                    {
                        selector: '#card-number',
                        placeholder: '0000 0000 0000 0000',
                        maskInput: true
                    },
                    expirationDate:
                    {
                        selector: '#expiration-date',
                        placeholder: 'MM/YYYY'
                    },
                    cvv: {
                        container: '#cvv',
                        placeholder: '123'
                    }
                }
            }, function (hostedFieldsErr, hostedFieldsInstance) {
                if (hostedFieldsErr) {
                    console.error(hostedFieldsErr);
                    return;
                }
                setHostedFieldsInstance(hostedFieldsInstance);
            });
        });
    }

    function toggleButtonEnabled(enable) {
        let payButton = document.getElementById('app-payment-btn-next');
        let processingLabel = document.getElementById('processing-label');
        payButton.disabled = !enable;
        processingLabel.style.display = enable ? 'none' : 'block';
    }

    function handleSubmit(event) {
        toggleButtonEnabled(false);
        let rootUrl = '.'; //todo: default this to the real url

        //console.log(process.env.NODE_ENV);

        if (process.env.NODE_ENV == 'development') {
            rootUrl = 'https://localhost:7058';
        }

        //console.log('root: ' + rootUrl);

        event.preventDefault();

        hostedFieldsInstance.tokenize(function (tokenizeErr, payload) {
            if (tokenizeErr) {
                console.error(tokenizeErr);

                toggleButtonEnabled(true);
                setPaymentResult(false);

                return;
            }

            const formData = new FormData(event.target);
            formData.append('nonce', payload.nonce);
            formData.append('paymentAmount', paymentAmount);
            formData.append('invoiceNumber', invoiceNumber);
            formData.append('emailAddress', emailAddress);

            fetch(`${rootUrl}/ProcessPayment`,  {
                method: 'POST',
                body: formData
            }).then(function (response) {
                return response.json();
            }).then(function (data) {
                if (!!data && !!data.success) {
                    setPaymentResult(true);
                    document.getElementById('processing-label').style.display = 'none';
                } else {
                    setPaymentResult(false);
                    toggleButtonEnabled(true);
                }
            });
        });
    }

    return (
        <>
            <div className="App">
                <div className="App-form">
                    <br/>
                    <form id="payment-form" onSubmit={handleSubmit}>
                        <div>
                            <label className="inv-field-label">Email Receipt To</label>
                            <input className="inv-field" type="text" value={emailAddress} placeholder="example@domain.com" onChange={e => setEmailAddress(e.target.value)} />
                        </div>
                        <div>
                            <label className="inv-field-label">Invoice Number</label>
                            <input className="inv-field" type="text" value={invoiceNumber} placeholder="00000" onChange={e => setInvoiceNumber(e.target.value)} />
                        </div>
                        <div>
                            <label className="inv-field-label"> Payment Amount</label>
                            <input className="inv-field" type="number" min="0.01" step="0.01" max="1000000.00" placeholder="100.00"  value={paymentAmount} onChange={e => setPaymentAmount(e.target.value)} />
                        </div>
                        <div>
                            <label className="inv-field-label">Card Number</label>
                            <div className="inv-field" id="card-number"></div>
                        </div>
                        <div>
                            <label className="inv-field-label">Expiration Date</label>
                            <div className="inv-field" id="expiration-date"></div>
                        </div>
                        <div>
                            <label className="inv-field-label">CVV</label>
                            <div className="inv-field" id="cvv"></div>
                        </div>
                        <br />
                        <button className="btn btn-secondary app-payment-btn-next" id="app-payment-btn-next" type="submit">Pay Now</button>
                        <br />
                        <label id="processing-label" style={{ display: 'none' }} ><em>Processing...</em></label>
                        <br />
                        {paymentResult === true && <p style={{ color: 'green' }}>Payment succeeded.<br/><a href="./">Click here to make another payment.</a></p>}
                        {paymentResult === false && <p style={{ color: 'red' }} >Payment failed. Please try again.</p>}
                        <br />
                        </form>
                </div>
            </div>
        </>
    );
}